import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab7ce511"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-fieldtype"]
const _hoisted_2 = { class: "field-controls" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "btn btn-xs btn-default"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.isConfigurationButton)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "btn btn-xs btn-primary-light",
        onClick: $setup.openConfigurationModal
      }, _toDisplayString($props.df.label), 1 /* TEXT */))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "control frappe-control editable",
        "data-fieldtype": $props.df.fieldtype
      }, [
        _createCommentVNode(" label "),
        _createElementVNode("div", _hoisted_2, [
          ($props.df.fieldtype == 'Heading')
            ? (_openBlock(), _createElementBlock("h4", _hoisted_3, [
                _renderSlot(_ctx.$slots, "label", {}, undefined, true)
              ]))
            : (_openBlock(), _createElementBlock("button", _hoisted_4, [
                _renderSlot(_ctx.$slots, "label", {}, undefined, true)
              ])),
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ]),
        _createCommentVNode(" description "),
        ($props.df.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "mt-2 description",
              innerHTML: $props.df.description
            }, null, 8 /* PROPS */, _hoisted_5))
          : _createCommentVNode("v-if", true)
      ], 8 /* PROPS */, _hoisted_1))
}